// AutoFetchUserDataWithDelete.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useUser } from './UserContext';
import { API_BASE_URL } from './api';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function AutoFetchUserDataWithDelete() {
  const { user } = useUser();
  const [userData, setUserData] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // New state variable for search query

  // Wrap fetchData with useCallback
  const fetchData = useCallback(async () => {
    if (!user || !user.uid) {
      setMessage("Please log in first!");
      setIsFetchingData(false);
      return;
    }

    try {
      setIsFetchingData(true);
      const response = await axios.post(`${API_BASE_URL}/get_user_data`, { user_id: user.uid });
      setUserData(response.data || []);
      setMessage("");
    } catch (error) {
      console.error('Error fetching user data:', error);
      setMessage("Failed to fetch user data.");
    } finally {
      setIsFetchingData(false);
    }
  }, [user]); // Include 'user' in the dependency array

  useEffect(() => {
    fetchData();
  }, [user, fetchData]); // Include 'fetchData' in the dependency array

  const handleCheckboxChange = (fileName) => {
    setSelectedFiles(prevSelected =>
      prevSelected.includes(fileName)
        ? prevSelected.filter(name => name !== fileName)
        : [...prevSelected, fileName]
    );
  };

  const handleDelete = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select at least one folder to delete.");
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/delete`, {
        user_id: user.uid,
        file_name: selectedFiles
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status === 'success') {
        setMessage(response.data.message);
        setSelectedFiles([]);
        fetchData();
      } else if (response.data.status === 'partial_success') {
        setMessage(response.data.message + " Errors: " + response.data.errors.join('; '));
        setSelectedFiles([]);
        fetchData();
      } else {
        setMessage(response.data.message || "Failed to delete selected folders.");
      }
    } catch (error) {
      console.error('Error deleting folders:', error);
      setMessage("Failed to delete selected folders.");
    }
  };

  // Filter userData based on searchQuery
  const filteredUserData = userData.filter((doc) =>
    doc.file_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '10%',
      transform: 'translateX(-50%)',
      fontSize: '24px',
      textAlign: 'center',
      width: '100%',
      maxWidth: '800px'
    }}>
      <h2>Your Uploaded Files</h2>

      {isFetchingData && (
        <div className="loading-circle" style={{ margin: '20px auto' }}></div>
      )}

      {userData.length > 0 ? (
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          {/* Search Input Field */}
          <input
            type="text"
            placeholder="Search files..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginBottom: '20px',
              fontSize: '16px',
            }}
          />

          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Image File Name</th>
                <th style={tableHeaderStyle}>Valid Image Numbers</th>
                <th style={tableHeaderStyle}>Already Analyzed Image Numbers</th>
              </tr>
            </thead>
            <tbody>
              {filteredUserData.map((doc, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>
                    <input
                      type="checkbox"
                      checked={selectedFiles.includes(doc.file_name)}
                      onChange={() => handleCheckboxChange(doc.file_name)}
                    />
                  </td>
                  <td style={tableCellStyle}>{doc.file_name}</td>
                  <td style={tableCellStyle}>
                    {Array.isArray(doc.valid_image_numbers) ? doc.valid_image_numbers.join(', ') : 'N/A'}
                  </td>
                  <td style={tableCellStyle}>
                    {Array.isArray(doc.analyzed_image_numbers) ? doc.analyzed_image_numbers.join(', ') : 'none'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            onClick={handleDelete}
            style={{
              backgroundColor: 'red',
              color: 'white',
              padding: '10px 20px',
              marginTop: '20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
        </div>
      ) : (
        !isFetchingData && <p>{message || "No data available."}</p>
      )}

      <style>{`
        .loading-circle {
          width: 40px;
          height: 40px;
          border: 4px solid rgba(0, 0, 255, 0.3);
          border-top-color: rgba(0, 0, 255, 1);
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin: 10px auto;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        table, th, td {
          border: 1px solid #ddd;
        }

        th, td {
          padding: 8px;
        }

        th {
          background-color: #f2f2f2;
        }
      `}</style>
    </div>
  );
}

// Define table styles
const tableHeaderStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  backgroundColor: '#f2f2f2',
  textAlign: 'left'
};

const tableCellStyle = {
  border: '1px solid #ddd',
  padding: '8px'
};

export default AutoFetchUserDataWithDelete;
