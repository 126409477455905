// Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';
import { auth } from './firebase';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // 导入图标
import './Sidebar.css'; // 导入 CSS 文件

function Sidebar() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  // 控制子菜单的展开与收起
  const [isAnalyzerOpen, setIsAnalyzerOpen] = useState(false);

  useEffect(() => {
    // 根据当前路径设置子菜单状态
    if (location.pathname.startsWith('/analyzer')) {
      setIsAnalyzerOpen(true);
    } else {
      setIsAnalyzerOpen(false);
    }
  }, [location.pathname]);

  const handleLogout = async () => {
    await auth.signOut();
    setUser(null);
    navigate('/');
  };

  // 判断主按钮是否激活
  const isActiveMain = (section) => {
    if (section === 'analyzer') {
      return location.pathname.startsWith('/analyzer');
    }
    return location.pathname === `/${section}`;
  };

  // 判断子按钮是否激活
  const isActiveSub = (path) => {
    return location.pathname === path;
  };

  // 处理 "Data Analyzer" 按钮点击，切换子菜单并导航
  const toggleAnalyzerMenu = () => {
    setIsAnalyzerOpen(!isAnalyzerOpen);
    if (!isAnalyzerOpen) {
      navigate('/analyzer'); // 导航到 /analyzer 当展开子菜单时
    } else {
      navigate('/'); // 导航回主页或其他适当的路径当收起子菜单时
    }
  };

  // 处理子菜单按钮点击
  const handleSubButtonClick = (path) => {
    navigate(path);
  };

  return (
    <div className="sidebar">
      {/* 添加粗體的 Mucus Analyze 標題 */}
      <h2 style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0rem' }}>Mucus Analyze </h2>

      <p>
        <strong>Your account:</strong> <br /> {user.email}
      </p>
      <button
        onClick={handleLogout}
        className="logout-button"
      >
        Logout
      </button>

      {/* 页面链接区域 */}
      <div style={{ marginTop: '20px', marginLeft: '-20px' }}>
        <Link
          to="/announcement"
          className={`main-button ${isActiveMain('announcement') ? 'active' : ''}`}
        >
          Announcement
        </Link>
        <Link
          to="/uploader"
          className={`main-button ${isActiveMain('uploader') ? 'active' : ''}`}
        >
          Data Uploader
        </Link>

        {/* "Data Analyzer" 按钮 */}
        <div>
          <div
            onClick={toggleAnalyzerMenu}
            className={`main-button ${isActiveMain('analyzer') ? 'active' : ''}`}
          >
            <span>Data Analyzer</span>
            {/* 添加箭头图标 */}
            <span className="icon">
              {isAnalyzerOpen ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {/* 如果子菜单展开，显示子按钮 */}
          {isAnalyzerOpen && (
            <div className={`submenu ${isAnalyzerOpen ? 'open' : ''}`}>
              <Link
                to="/analyzer/choose"
                className={`sub-button ${isActiveSub('/analyzer/choose') ? 'active' : ''}`}
                onClick={() => handleSubButtonClick('/analyzer/choose')}
              >
                1st : Choose Analyze Condition
              </Link>
              <Link
                to="/analyzer/result"
                className={`sub-button ${isActiveSub('/analyzer/result') ? 'active' : ''}`}
                onClick={() => handleSubButtonClick('/analyzer/result')}
              >
                2nd : See Analyze Result
              </Link>
            </div>
          )}
        </div>

        <Link
          to="/management"
          className={`main-button ${isActiveMain('management') ? 'active' : ''}`}
        >
          Data Management
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
