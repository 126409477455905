// Step3_ChooseAnalyzeCondition.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useUser } from "./UserContext"; // From UserContext
import { API_BASE_URL } from './api';
import Select from 'react-select'; // Import react-select

axios.defaults.headers.post['Content-Type'] = 'application/json';

function App() {
  const { user } = useUser(); // Get user information
  const [formData, setFormData] = useState({
    file_name: '',
    img_num: '',
    cell_channel: 0,
    mucus_channel: 1,
    cell_channel_noise_brightness_threshold: 10,
    mucus_channel_noise_brightness_threshold: 20,
  });
  const [validImageNumbers, setValidImageNumbers] = useState([]); // For storing valid_image_numbers of the selected file
  const [stage2Response, setStage2Response] = useState(null);
  const [previewData, setPreviewData] = useState(null); // Store preview data
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false); // Control preview loading state

  // Refs for the two canvas elements
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);

  // State variables for react-select
  const [fileOptionsFormatted, setFileOptionsFormatted] = useState([]);
  const [selectedFileOption, setSelectedFileOption] = useState(null);

  useEffect(() => {
    if (user && user.uid) { // Check if user is logged in
      const fetchUserData = async () => {
        try {
          const response = await axios.post(`${API_BASE_URL}/get_user_data`, {
            user_id: user.uid,
          });
          const files = response.data.map(file => ({
            name: file.file_name.replace('.lif', ''),
            fullName: file.file_name,
            totalImages: file.total_images,
            validImageNumbers: file.valid_image_numbers, // Get valid_image_numbers
          }));
          // Format options for react-select
          const formattedOptions = files.map(file => ({
            value: file.fullName,
            label: file.name,
            valid_image_numbers: file.validImageNumbers,
          }));
          setFileOptionsFormatted(formattedOptions);
        } catch (err) {
          console.error(err.response ? err.response.data.error : "An error occurred");
        }
      };
      fetchUserData();
    }
  }, [user]);

  const handleFileChange = (selectedOption) => {
    setSelectedFileOption(selectedOption);
    setFormData(prevState => ({
      ...prevState,
      file_name: selectedOption ? selectedOption.value : '',
      img_num: '', // Reset img_num
    }));

    if (selectedOption) {
      const validImageNumbers = selectedOption.valid_image_numbers;
      setValidImageNumbers(validImageNumbers);
    } else {
      setValidImageNumbers([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Fetch preview data on Preview button click
  const handlePreview = async () => {
    const { file_name, img_num } = formData;
    if (!file_name || !img_num) {
      alert("Please select a file and an image number for preview.");
      return;
    }

    setIsPreviewLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/get_preview_data`, {
        user_id: user.uid,
        file_name: file_name.replace('.lif', ''), // Remove .lif suffix
        image_num: img_num,
      });
      setPreviewData(response.data);
    } catch (error) {
      console.error("Error fetching preview data:", error);
      alert("Failed to fetch preview data.");
    } finally {
      setIsPreviewLoading(false);
    }
  };

  // Analyze data on Submit and Analyze button click
  const handleAnalyze = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const analyzeRes = await axios.post(`${API_BASE_URL}/analyze`, {
        user_id: user.uid,
        file_name: formData.file_name.replace('.lif', ''), // Remove .lif suffix
        img_num: formData.img_num,
        cell_channel: formData.cell_channel,
        mucus_channel: formData.mucus_channel,
        cell_channel_noise_brightness_threshold: formData.cell_channel_noise_brightness_threshold,
        mucus_channel_noise_brightness_threshold: formData.mucus_channel_noise_brightness_threshold,
      });
      setStage2Response(analyzeRes.data);
    } catch (error) {
      console.error(`An error occurred: ${error.message}`);
      alert("Failed to analyze data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Use Canvas to render RGB images from preview data
  useEffect(() => {
    if (previewData) {
      renderImages(previewData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewData, formData.cell_channel_noise_brightness_threshold, formData.mucus_channel_noise_brightness_threshold]);

  const renderImages = (data) => {
    const img1 = {
      blue: data["preview_img1_ch0"],
      red: data["preview_img1_ch1"],
    };
    const img2 = {
      blue: data["preview_img2_ch0"],
      red: data["preview_img2_ch1"],
    };

    const images = [img1, img2];

    images.forEach((img, index) => {
      const canvas = index === 0 ? canvasRef1.current : canvasRef2.current;
      const ctx = canvas.getContext('2d');

      const originalWidth = img.blue[0].length;
      const originalHeight = img.blue.length;

      const canvasWidth = 800;
      const canvasHeight = 200;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Create offscreen Canvas to handle raw image data
      const offscreenCanvas = document.createElement('canvas');
      offscreenCanvas.width = originalWidth;
      offscreenCanvas.height = originalHeight;
      const offscreenCtx = offscreenCanvas.getContext('2d');

      // Initialize ImageData
      const imageData = offscreenCtx.createImageData(originalWidth, originalHeight);
      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = 0;     // Red
        imageData.data[i + 1] = 0; // Green
        imageData.data[i + 2] = 0; // Blue
        imageData.data[i + 3] = 255; // Alpha
      }

      const cellThreshold = formData.cell_channel_noise_brightness_threshold;
      const mucusThreshold = formData.mucus_channel_noise_brightness_threshold;

      const processedBlue = img.blue.map(row => row.map(value => value >= cellThreshold ? 255 : 0));
      const processedRed = img.red.map(row => row.map(value => value >= mucusThreshold ? 255 : 0));

      for (let z = 0; z < originalHeight; z++) {
        for (let x = 0; x < originalWidth; x++) {
          const blueValue = processedBlue[z][x];
          const redValue = processedRed[z][x];

          let color = [0, 0, 0]; // [Red, Green, Blue]

          if (blueValue === 255 && redValue === 255) {
            // Both red and blue, display blue
            color = [0, 0, 255];
          } else if (blueValue === 255) {
            // Only blue
            color = [0, 0, 255];
          } else if (redValue === 255) {
            // Only red
            color = [255, 0, 0];
          }

          const pixelIndex = (z * originalWidth + x) * 4;
          imageData.data[pixelIndex] = color[0];
          imageData.data[pixelIndex + 1] = color[1];
          imageData.data[pixelIndex + 2] = color[2];
          // Alpha remains 255
        }
      }

      // Put processed ImageData into offscreen Canvas
      offscreenCtx.putImageData(imageData, 0, 0);

      // Clear visible Canvas and set image smoothing
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Flip y-axis
      ctx.save();
      ctx.translate(0, canvasHeight);
      ctx.scale(1, -1);

      // Draw image
      ctx.drawImage(offscreenCanvas, 0, 0, originalWidth, originalHeight, 0, 0, canvasWidth, canvasHeight);

      ctx.restore();
    });
  };

  return (
    <div className="container mx-auto p-4 flex flex-col items-center" style={{ marginLeft: "30%" }}>
      <div className="selector-container w-full max-w-2xl text-left p-5 mb-20">
        <h1 className="text-3xl font-bold mb-8 text-center">Mucus Analysis Parameter Poster</h1>

        {/* File Name Selection */}
        <div className="mb-6 flex items-center">
          <label htmlFor="file_name" className="block text-lg font-bold text-left mb-2 w-1/3 pr-4">
            1. Select File Name :
          </label>
          <div className="w-2/3" style={{ marginLeft: "0.1rem", width: "30%"}}>
            <Select
              name="file_name"
              value={selectedFileOption}
              onChange={handleFileChange}
              options={fileOptionsFormatted}
              placeholder="Select a file"
              isClearable
            />
          </div>
        </div>

        {/* Use valid_image_numbers to dynamically generate Image Number dropdown */}
        <div className="mb-6 flex items-center">
          <label htmlFor="img_num" className="block text-lg font-bold text-left mb-2 w-1/3 pr-4">
            2. Image Number (.lif file image number) :
          </label>
          <select
            name="img_num"
            value={formData.img_num}
            onChange={handleInputChange}
            className="p-2 border rounded w-2/3"
            disabled={!validImageNumbers.length}
          >
            <option value="">Select image number</option>
            {validImageNumbers.map((num, i) => (
              <option key={i} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>

        {/* Other analysis options */}
        <form className="space-y-6" onSubmit={handleAnalyze}>
          {/* Cell Marker Selection */}
          <div className="mb-6 flex items-center">
            <label htmlFor="cell_channel" className="block text-lg font-bold text-left mb-2 w-1/3 pr-4">
              3. Cell Marker (Reference Channel) :
            </label>
            <select
              name="cell_channel"
              value={formData.cell_channel}
              onChange={handleInputChange}
              className="p-2 border rounded w-2/3"
            >
              <option value="0">Blue</option>
              <option value="1">Red</option>
              <option value="2">Green</option>
              <option value="3">Yellow</option>
            </select>
          </div>

          {/* Mucus Marker Selection */}
          <div className="mb-6 flex items-center">
            <label htmlFor="mucus_channel" className="block text-lg font-bold text-left mb-2 w-1/3 pr-4">
              4. Mucus Marker (Measure Channel) :
            </label>
            <select
              name="mucus_channel"
              value={formData.mucus_channel}
              onChange={handleInputChange}
              className="p-2 border rounded w-2/3"
            >
              <option value="1">Red</option>
              <option value="0">Blue</option>
              <option value="2">Green</option>
              <option value="3">Yellow</option>
            </select>
          </div>

          {/* Cell Channel Noise Brightness Threshold */}
          <div className="mb-6 flex items-center">
            <label htmlFor="cell_channel_noise_brightness_threshold" className="block text-lg font-bold text-left mb-2 w-1/3 pr-4">
              5. Cell Channel Noise Brightness Threshold (8bit Range 0~255) :
            </label>
            <input
              type="number"
              name="cell_channel_noise_brightness_threshold"
              value={formData.cell_channel_noise_brightness_threshold}
              onChange={handleInputChange}
              min="0"
              max="255"
              step="0.5"
              className="p-2 border rounded w-2/3"
            />
          </div>

          {/* Mucus Channel Noise Brightness Threshold */}
          <div className="mb-6 flex items-center">
            <label htmlFor="mucus_channel_noise_brightness_threshold" className="block text-lg font-bold text-left mb-2 w-1/3 pr-4">
              6. Mucus Channel Noise Brightness Threshold (8bit Range 0~255) :  
            </label>
            <input
              type="number"
              name="mucus_channel_noise_brightness_threshold"
              value={formData.mucus_channel_noise_brightness_threshold}
              onChange={handleInputChange}
              min="0"
              max="255"
              step="0.5"
              className="p-2 border rounded w-2/3"
            />
          </div>

          {/* Preview and Submit Buttons */}
          <div className="flex space-x-4 items-center">
            <button
              type="button"
              onClick={handlePreview}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200 flex-1 flex items-center justify-center"
              disabled={isPreviewLoading}
            >
              {isPreviewLoading ? 'Previewing...' : 'Preview'}
            </button>
            <div className="relative flex items-center">
              <button
                type="submit"
                className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition duration-200 flex-1 flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Submit and Analyze'}
              </button>
              {isLoading && (
                <div className="spinner absolute left-full ml-5" style={{ marginTop: "8px" }}></div>
              )}
            </div>
          </div>
        </form>

        {/* Stage 2: Analyze Response */}
        {stage2Response && (
          <div className="mt-6 w-full max-w-2xl">
            <h3 className="text-xl font-bold mb-2 text-red-500" style={{ color: "red" }} >Under analyzing your image. You can move on "2nd : See Analyze Result" page when "Data Management" page show the image you POST had already analyze. </h3>
          </div>
        )}

        {/* Display Preview Images */}
        {previewData && (
          <div className="mt-6 w-full max-w-2xl">
            <h3 className="text-xl font-bold mb-2">Preview Images:</h3>
            <div className="flex flex-col items-center">
              {/* Title for Image1 */}
              <h4 className="text-lg font-semibold mb-1">Image1 (Slice at y=350) </h4>
              <canvas ref={canvasRef1} className="preview-image mb-4" style={{ height: "200px", width: "800px", border: "1px solid #ccc" }}></canvas>
              
              {/* Title for Image2 */}
              <h4 className="text-lg font-semibold mb-1" style={{ marginTop: "10px" }}>Image2 (Slice at y=700) </h4>
              <canvas ref={canvasRef2} className="preview-image" style={{ height: "200px", width: "800px", border: "1px solid #ccc" , marginTop:"10px" }}></canvas>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
