// NewShowDistributionData.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { saveAs } from 'file-saver';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label,
} from 'recharts';

function NewShowDistributionData({ scaleData, zThickness, file_name, img_num  }) {
  const [matrixData, setMatrixData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [thicknessDistribution, setThicknessDistribution] = useState([]);
  const [error, setError] = useState(null);
  const [classificationStep, setClassificationStep] = useState(1);

  const chartRef = useRef(null);

  const classifyData = useCallback((data, step) => {
    if (!data || data.length === 0) {
      setThicknessDistribution([]);
      return;
    }

    const maxValue = data.reduce((max, val) => (val > max ? val : max), 0);
    const maxBins = 1000;
    const numberOfBins = Math.min(Math.ceil(maxValue / step), maxBins);

    if (numberOfBins > maxBins) {
      setError(`Number of classifications exceeds the maximum limit (${maxBins}). Please increase the classification step.`);
      return;
    }

    const bins = Array.from({ length: numberOfBins }, (_, i) => ({
      min: i * step,
      max: (i + 1) * step,
      count: 0,
    }));

    for (let i = 0; i < data.length; i++) {
      const value = data[i];
      const binIndex = Math.min(Math.floor(value / step), numberOfBins - 1);
      bins[binIndex].count += 1;
    }

    const total = data.length;
    const distribution = bins.map(bin => ({
      range: `${bin.min.toFixed(1)} ~ ${bin.max.toFixed(1)}`,
      percentage: parseFloat(((bin.count / total) * 100).toFixed(1)),
    }));

    setThicknessDistribution(distribution);
  }, [setThicknessDistribution, setError]);

  const processFlattenedData = useCallback((flattened, step, classificationStep) => {
    if (step === null || step === undefined) {
      setError("new_step is not set.");
      return;
    }

    const newMatrix = flattened.map(value => value * step);
    setMatrixData(newMatrix);

    classifyData(newMatrix, classificationStep);
  }, [setError, setMatrixData, classifyData]);

  const startLoading = useCallback(() => {
    setLoading(true);
    setComplete(false);
    setError(null);

    try {
      if (!scaleData || !scaleData.new_step) {
        setError("new_step not found in scale_data.");
        setLoading(false);
        return;
      }

      if (!zThickness || !Array.isArray(zThickness) || !Array.isArray(zThickness[0])) {
        setError("z_thickness format is incorrect. It should be a two-dimensional array.");
        setLoading(false);
        return;
      }

      const flattened = zThickness.flat();
      processFlattenedData(flattened, scaleData.new_step, classificationStep);
    } catch (error) {
      console.error("Error processing data:", error);
      setError("Error processing data.");
    } finally {
      setLoading(false);
      setComplete(true);
    }
  }, [scaleData, zThickness, classificationStep, processFlattenedData]);

  const handleClassificationStepChange = (e) => {
    const step = parseFloat(e.target.value);
    if (isNaN(step) || step < 0.1 || step > 100) {
      setError("Classification step must be between 0.1 and 100.");
      return;
    }
    setError(null);
    setClassificationStep(step);
    classifyData(matrixData, step);
  };

  const downloadResultsAsTxt = () => {
    let txtContent = "Classification Results:\n\n";
    txtContent += "Range (µm)\tPercentage of Total (%)\n";
    thicknessDistribution.forEach(range => {
      txtContent += `${range.range}\t${range.percentage}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    saveAs(blob, `${file_name}_img${img_num}_mucus_thickness_distribution.txt`);
  };

  const downloadChartAsPNG = () => {
    if (!chartRef.current) {
      setError("Chart is not available for download.");
      return;
    }

    const svg = chartRef.current.querySelector('svg');
    if (!svg) {
      setError("SVG element not found.");
      return;
    }

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `${file_name}_img${img_num}_mucus_thickness_distribution.png`);
        } else {
          setError("Failed to generate image blob.");
        }
      });
    };

    img.onerror = () => {
      setError("Failed to load SVG image.");
    };

    img.src = url;
  };

  useEffect(() => {
    startLoading();
  }, [startLoading]);

  return (
    <div style={{ padding: '20px', marginTop: '0px', marginLeft: '10%' }}>
      {/* Title, Input, and Download Buttons Container */}
      <div style={{
        marginLeft: '-10%',
        marginBottom: '0%',
        display: 'flex',
        flexDirection: 'column',   // Vertical alignment
        alignItems: 'center'       // Center alignment
      }}>
        <h1 style={{ marginBottom: '20px' }}>Matrix Thickness Classification</h1>

        <label style={{ fontSize: '18px', marginBottom: '20px' }}>
          Classification Step (Min=0.1, Max=100, Step=0.1; Unit: µm):
          <input
            type="number"
            min="0.1"
            max="100"
            step="0.1"
            value={classificationStep}
            onChange={handleClassificationStepChange}
            style={{ marginLeft: '10px', width: '80px' }}
          />
        </label>

        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={downloadResultsAsTxt}
            style={{ padding: '10px 20px' }}
            disabled={!complete || error}
          >
            Download Results (.txt)
          </button>
          <button
            onClick={downloadChartAsPNG}
            style={{ padding: '10px 20px' }}
            disabled={!complete || error}
          >
            Download Chart (PNG)
          </button>
        </div>
      </div>

      {/* Error Message Display */}
      {error && <p style={{ color: 'red', marginTop: '20px' }}>{error}</p>}

      {/* Table and Chart Area */}
      {complete && !loading && !error && (
        <div style={{ marginTop: '30px', marginLeft: '5%', display: 'flex', flexDirection: 'row', gap: '30px' }}>
          {/* Table Area */}
          <div style={{ flex: 0.9 }}>
            <h2>Classification Results:</h2>
            <table border="1" cellPadding="10" style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: '50%' }}>Range (µm)</th>
                  <th style={{ width: '50%' }}>Percentage of Total (%)</th>
                </tr>
              </thead>
              <tbody>
                {thicknessDistribution.map((range, index) => (
                  <tr key={index}>
                    <td>{range.range}</td>
                    <td>{range.percentage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Chart Area */}
          <div style={{ flex: 3 }} ref={chartRef}>
            <h2>Thickness Distribution:</h2>
            {thicknessDistribution.length > 0 ? (
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={thicknessDistribution}
                  margin={{
                    top: 20, right: 30, left: 60, bottom: 100,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="range"
                    angle={-45}
                    textAnchor="end"
                    interval={0}
                    height={60}
                  >
                    <Label
                      value="Thickness (µm)"
                      position="insideBottom"
                      offset={-40}
                      style={{ textAnchor: 'middle' }}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value="Percentage (%)"
                      angle={-90}
                      position="insideLeft"
                      style={{ textAnchor: 'middle' }}
                    />
                  </YAxis>
                  <Tooltip />
                  <Bar dataKey="percentage" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <p>No data available for the chart.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewShowDistributionData;
