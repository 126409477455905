// Step3_SeeAnalyzeResult.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "./UserContext"; // From UserContext
import NewTopView from "./NewTopView";
import NewYSlice from "./NewYSlice";
import NewStatisticValuePrint from "./NewStatisticValuePrint";
import NewShowDistributionData from "./NewShowDistributionData";
import "./App.css"; // Import the CSS file for spinner
import { API_BASE_URL } from './api';
import Select from 'react-select'; // Import react-select

axios.defaults.headers.post['Content-Type'] = 'application/json';

function App() {
  const { user } = useUser(); // Get user object
  const [fileOptionsFormatted, setFileOptionsFormatted] = useState([]); // Formatted file options for react-select
  const [selectedFileOption, setSelectedFileOption] = useState(null); // Selected file option
  const [imageOptions, setImageOptions] = useState([]);
  const [selectedImageNum, setSelectedImageNum] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [processComplete, setProcessComplete] = useState(false);
  const [viewMode, setViewMode] = useState(null); // "heatmap", "yslice", "statistic", or "distribution"

  // Data to be passed as props
  const [cellResult, setCellResult] = useState(null);
  const [mucusResult, setMucusResult] = useState(null);
  const [zThickness, setZThickness] = useState(null);
  const [stasticData, setStasticData] = useState(null);
  const [scaleData, setScaleData] = useState(null);

  useEffect(() => {
    if (user && user.uid) {
      const fetchFileNames = async () => {
        try {
          const response = await axios.post(`${API_BASE_URL}/get_user_data`, { user_id: user.uid });
          // Removed setFileOptions since fileOptions is not used
          
          // Format file options for react-select
          const formattedOptions = response.data.map(file => ({
            value: file.file_name,
            label: file.file_name.replace('.lif', ''),
            analyzed_image_numbers: file.analyzed_image_numbers
          }));
          setFileOptionsFormatted(formattedOptions);

        } catch (err) {
          console.error("Error fetching file names:", err);
        }
      };
      fetchFileNames();
    }
  }, [user]);

  const handleFileChange = (selectedOption) => {
    setSelectedFileOption(selectedOption);
    setSelectedImageNum(''); // Clear selected image number

    if (selectedOption) {
      const analyzedImageNumbers = selectedOption.analyzed_image_numbers;

      if (Array.isArray(analyzedImageNumbers)) {
        const sortedImageOptions = analyzedImageNumbers.sort((a, b) => a - b);
        setImageOptions(sortedImageOptions);
      } else {
        setImageOptions([]);
      }
    } else {
      setImageOptions([]);
    }
  };

  const handleConfirm = async () => {
    if (!selectedFileOption || selectedImageNum === '') {
      alert("请选择一个文件和图像编号。");
      return;
    }

    const fileNameWithoutExtension = selectedFileOption.value.replace('.lif', '');
    setIsConfirming(true);
    setProcessComplete(false);

    try {
      const response = await axios.post(`${API_BASE_URL}/get_plot_data`, {
        user_id: user.uid,
        file_name: fileNameWithoutExtension,
        image_num: selectedImageNum,
      });

      setCellResult(response.data.cell_result);
      setMucusResult(response.data.mucus_result);
      setZThickness(response.data.z_thickness);
      setStasticData(response.data.stastic_data);
      setScaleData(response.data.scale_data);

      setProcessComplete(true);
    } catch (error) {
      console.error("Error confirming selection:", error);
      alert("获取数据时出错。");
    } finally {
      setIsConfirming(false);
    }
  };

  // 提取 file_name 和 img_num 以便传递给子组件
  const file_name = selectedFileOption ? selectedFileOption.value.replace('.lif', '') : '';
  const img_num = selectedImageNum;


  return (
    <div className="app-container" style={{  marginTop: "-2%" , marginLeft: "33%", display: "flex", flexDirection: "column", alignItems: "Left" }}>
      <div
        className="selector-container"
        style={{
          width: "100%",
          maxWidth: "600px",
          textAlign: "Left",
          padding: "5%",
          marginTop: "0%",
          marginBottom: "20px"
        }}
      >
        <h1 className="text-3xl font-bold mb-8">File and Image Selector</h1>

        <div className="mb-6 flex items-center justify-between">
          <label htmlFor="file_name" className="text-lg font-bold" style={{ width: "30%", textAlign: "left" }}>
            1. Select File Name :
          </label>
          <div style={{ marginLeft: "0.1rem", width: "50%"}}>
            <Select
              name="file_name"
              value={selectedFileOption}
              onChange={handleFileChange}
              options={fileOptionsFormatted}
              placeholder="Select a file"
              isClearable
            />
          </div>
        </div>

        <div className="mb-6 flex items-center justify-between">
          <label htmlFor="img_num" className="text-lg font-bold" style={{ width: "30%", textAlign: "left" }}>
            2. Select Image Number :
          </label>
          <select
            name="img_num"
            value={selectedImageNum}
            onChange={(e) => setSelectedImageNum(e.target.value)}
            className="p-2 border rounded w-full text-center"
            disabled={!imageOptions.length}
            style={{ marginLeft: "1rem" }}
          >
            <option value="">Select an image number</option>
            {imageOptions.map((num, index) => (
              <option key={index} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>

        {/* Button and Spinner Container */}
        <div className="button-spinner-container" style={{ display: 'flex', alignItems: 'Left', justifyContent: 'Left' }}>
          <button 
            onClick={handleConfirm} 
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
            disabled={isConfirming}
            style={{ width: '100%', maxWidth: '80px' , marginTop: '8px'}}
          >
            {isConfirming ? "Loading..." : "Confirm"}
          </button>
          {isConfirming && (
            <div className="spinner" style={{ marginLeft: '5%' , marginTop: '5px'}}></div>
          )}
        </div>

        {processComplete && (
          <>
            <div className="mt-4 text-green-500 font-semibold">
              Complete Process
            </div>
            <div className="flex gap-4 mt-4 flex-wrap justify-center" >
              <button 
                onClick={() => setViewMode("heatmap")} 
                className="font-semibold transition duration-200 shadow-lg"
                style={{ 
                  fontSize: "1.1rem", 
                  minWidth: "150px",
                  minHeight:"30px", 
                  marginTop: '10px', 
                  marginLeft: '-20%',
                  backgroundColor: viewMode === "heatmap" ? "#00008B" : "#ADD8E6", // 深蓝或浅蓝
                  color: "#ffffff", 
                  border: "0.1px solid #000000", 
                  borderRadius: "3px"
                }}
              >
                Heat map
              </button>
              <button 
                onClick={() => setViewMode("yslice")} 
                className="font-semibold transition duration-200 shadow-lg"
                style={{ 
                  fontSize: "1.1rem", 
                  minWidth: "150px",
                  minHeight:"30px", 
                  marginTop: '10px', 
                  backgroundColor: viewMode === "yslice" ? "#00008B" : "#ADD8E6", // 深蓝或浅蓝
                  color: "#ffffff", 
                  border: "0.1px solid #000000", 
                  borderRadius: "3px"
                }}
              >
                Y Slice
              </button>
              <button 
                onClick={() => setViewMode("statistic")} 
                className="font-semibold transition duration-200 shadow-lg"
                style={{ 
                  fontSize: "1.1rem", 
                  minWidth: "150px",
                  minHeight:"30px", 
                  marginTop: '10px', 
                  backgroundColor: viewMode === "statistic" ? "#00008B" : "#ADD8E6", // 深蓝或浅蓝
                  color: "#ffffff", 
                  border: "0.1px solid #000000", 
                  borderRadius: "3px"
                }}
              >
                Statistic
              </button>
              <button 
                onClick={() => setViewMode("distribution")} 
                className="font-semibold transition duration-200 shadow-lg"
                style={{ 
                  fontSize: "1.1rem", 
                  minWidth: "150px",
                  minHeight:"30px", 
                  marginTop: '10px', 
                  backgroundColor: viewMode === "distribution" ? "#00008B" : "#ADD8E6", // 深蓝或浅蓝
                  color: "#ffffff", 
                  border: "0.1px solid #000000", 
                  borderRadius: "3px"
                }}
              >
                Distribution
              </button>
            </div>
          </>
        )}
      </div>

      <div className="content-container" style={{ width: "100%", maxWidth: "1200px", marginTop: "-80px",marginLeft: "-28%" }}>
        {viewMode === "heatmap" && (
          <NewTopView 
            zThickness={zThickness} 
            scaleData={scaleData} 
            cellResult={cellResult} 
            mucusResult={mucusResult} 
            stasticData={stasticData} 
            file_name={file_name}
            img_num={img_num}
          />
        )}

        {viewMode === "yslice" && (
          <NewYSlice 
            zThickness={zThickness} 
            scaleData={scaleData} 
            cellResult={cellResult} 
            mucusResult={mucusResult} 
            stasticData={stasticData} 
            file_name={file_name}
            img_num={img_num}
          />
        )}

        {viewMode === "statistic" && (
          <NewStatisticValuePrint 
            zThickness={zThickness} 
            scaleData={scaleData} 
            cellResult={cellResult} 
            mucusResult={mucusResult} 
            stasticData={stasticData} 
          />
        )}

        {viewMode === "distribution" && (
          <NewShowDistributionData 
            zThickness={zThickness} 
            scaleData={scaleData} 
            cellResult={cellResult} 
            mucusResult={mucusResult} 
            stasticData={stasticData} 
            file_name={file_name}
            img_num={img_num}
          />
        )}
      </div>
    </div>
  );
}

export default App;
