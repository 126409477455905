// DataUploader.js
import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from './UserContext';
import { API_BASE_UPLOAD_URL } from './api';
import { API_BASE_URL } from './api';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function DataUploaderPage() {
  const { user } = useUser();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(false);

  // 檔案選擇處理
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith('.lif')) {
      setFile(selectedFile);
      setMessage("");
      setUserData(null);
    } else {
      alert("Please upload a .lif file");
    }
  };

  // 上傳檔案並附帶 user.uid
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a .lif file first!");
      return;
    }
    if (!user || !user.uid) {
      alert("Please log in first!");
      return;
    }

    // 將 user.uid 存儲在本地變數中，確保在整個函式中使用的是同一個值
    const userId = user.uid;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);

    setIsLoading(true);
    setMessage("");
    setUserData(null);

    try {
      // 上傳檔案
      await axios.post(`${API_BASE_UPLOAD_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage("File uploaded successfully, Data List in your account");

      // 上傳成功後，獲取用戶數據，傳遞 userId
      await fetchUserData(userId);
    } catch (error) {
      console.error('Error uploading file:', error);
      if (error.response) {
        console.error('Upload error response data:', error.response.data);
        setMessage(`Failed to upload file: ${error.response.status} ${error.response.statusText}`);
      } else if (error.request) {
        console.error('Upload error request:', error.request);
        setMessage("Failed to upload file: No response from server");
      } else {
        console.error('Upload error message:', error.message);
        setMessage(`Failed to upload file: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // 獲取用戶數據，接受 userId 作為參數
  const fetchUserData = async (userId) => {
    if (!userId) {
      console.warn("User ID is not available.");
      setMessage("User information is missing.");
      return;
    }
  
    console.log("Fetching data for user_id:", userId);
  
    setIsFetchingData(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/get_user_data`, { user_id: userId }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('User data response:', response.data);
      // 假设返回的数据显示在 response.data 中
      if (response.data) {
        setUserData(response.data);
      } else {
        console.warn('Unexpected response structure:', response.data);
        setMessage("Unexpected response from server");
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
        setMessage(`Failed to fetch user data: ${error.response.data.error || error.response.statusText}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setMessage("Failed to fetch user data: No response from server");
      } else {
        console.error('Error setting up request:', error.message);
        setMessage(`Failed to fetch user data: ${error.message}`);
      }
    } finally {
      setIsFetchingData(false);
    }
  };

  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '10%',
      transform: 'translateX(-50%)',
      fontSize: '24px',
      textAlign: 'center',
      width: '80%',
      maxWidth: '800px'
    }}>
      <h2>Data Uploader Page</h2>

      {/* 檔案選擇器 */}
      <input type="file" accept=".lif" onChange={handleFileChange} />

      {/* 上傳按鈕 */}
      <button onClick={handleUpload} disabled={isLoading} style={{ marginLeft: '10px' }}>
        {isLoading ? 'Uploading...' : 'Upload'}
      </button>

      {/* 顯示上傳結果訊息 */}
      <p>{message}</p>

      {/* loading circle */}
      {isLoading && (
        <div className="loading-circle"></div>
      )}

      {/* 顯示用戶數據表格 */}
      {isFetchingData && (
        <div className="loading-circle" style={{ margin: '20px auto' }}></div>
      )}

      {userData && userData.length > 0 && (
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          <h3>Your Uploaded Files</h3>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>File Name</th>
                <th style={tableHeaderStyle}>Valid Image Numbers</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((doc, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{doc.file_name}</td>
                  <td style={tableCellStyle}>
                    {Array.isArray(doc.valid_image_numbers) ? doc.valid_image_numbers.join(', ') : 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <style>{`
        .loading-circle {
          width: 40px;
          height: 40px;
          border: 4px solid rgba(0, 0, 255, 0.3);
          border-top-color: rgba(0, 0, 255, 1);
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin: 10px auto;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        table, th, td {
          border: 1px solid #ddd;
        }

        th, td {
          padding: 8px;
        }

        th {
          background-color: #f2f2f2;
        }
      `}</style>
    </div>
  );
}

// 定義表格樣式
const tableHeaderStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  backgroundColor: '#f2f2f2',
  textAlign: 'left'
};

const tableCellStyle = {
  border: '1px solid #ddd',
  padding: '8px'
};

export default DataUploaderPage;
