// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";  // 添加 Firestore 函數
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // 添加 Authentication 函數
// import { getAnalytics } from "firebase/analytics";  // 這可以保留或移除，看你是否需要 Analytics

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbEQUIsRpXw5AgNwqWEfiF3PGJNSHy4rQ",
  authDomain: "mucus-analyze-78e70.firebaseapp.com",
  projectId: "mucus-analyze-78e70",
  storageBucket: "mucus-analyze-78e70.appspot.com",
  messagingSenderId: "459353890089",
  appId: "1:459353890089:web:c29dcb77c3b4022ac82b38",
  measurementId: "G-KJ8F4Y7LB6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const db = getFirestore(app);
const storage = getStorage(app);

// Initialize Authentication and set up Google Auth Provider
const auth = getAuth(app); // 初始化 Authentication
const provider = new GoogleAuthProvider(); // 設置 Google 登入提供商

export { db, storage, auth, provider };