import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';

function NewYslice({ cellResult, mucusResult, scaleData , file_name, img_num }) {
  const [cellDAPISize, setCellDAPISize] = useState(null);
  const [mucusWGASize, setMucusWGASize] = useState(null);
  const [yValue, setYValue] = useState(0);
  const [xRange, setXRange] = useState(0);
  const [yRange, setYRange] = useState(0);
  const [xScaleState, setXScale] = useState(0);
  const [newStep, setNewStep] = useState(0);
  const [matrixCellDAPI, setMatrixCellDAPI] = useState([]);
  const [matrixMucusWGA, setMatrixMucusWGA] = useState([]);
  const [zValue, setZValue] = useState(0);

  const canvasRef = useRef(null);
  const svgRef = useRef(null);

  const margin = { top: 20, right: 30, bottom: 50, left: 50 };

  useEffect(() => {
    if (scaleData && cellResult && mucusResult) {
      const xRangeNum = Number(scaleData.x_range);
      const yRangeNum = Number(scaleData.y_range);
      const xScaleNum = Number(scaleData.scale_x);
      const zValueNum = Number(scaleData.new_z_range);
      const newStepNum = Number(scaleData.new_step);

      setXRange(xRangeNum);
      setYRange(yRangeNum);
      setXScale(xScaleNum);
      setZValue(zValueNum);
      setNewStep(newStepNum);

      setMatrixCellDAPI(cellResult);
      setMatrixMucusWGA(mucusResult);

      const zCellDAPI = cellResult.length / (xRangeNum * yRangeNum);
      setCellDAPISize(`${xRangeNum} x ${yRangeNum} x ${zCellDAPI}`);

      const zMucusWGA = mucusResult.length / (xRangeNum * yRangeNum);
      setMucusWGASize(`${xRangeNum} x ${yRangeNum} x ${zMucusWGA}`);

      if (yValue >= yRangeNum) {
        setYValue(yRangeNum - 1);
      }
    }
  }, [scaleData, cellResult, mucusResult, yValue]);

  useEffect(() => {
    if (yRange <= 0 || matrixCellDAPI.length === 0 || matrixMucusWGA.length === 0) return;

    const width = xRange;
    const height = zValue;

    const containerWidth = 800;
    const containerHeight = 200;

    const innerWidth = containerWidth - margin.left - margin.right;
    const innerHeight = containerHeight - margin.top - margin.bottom;

    if (yValue < 0 || yValue > yRange - 1) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = innerWidth;
    canvas.height = innerHeight;

    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext('2d');
    const imageData = tempCtx.createImageData(width, height);

    for (let z = 0; z < height; z++) {
      const cellDAPISlice = matrixCellDAPI[z][yValue];
      const mucusWGASlice = matrixMucusWGA[z][yValue];
      const flippedZ = height - z - 1;

      for (let x = 0; x < xRange; x++) {
        const index = (flippedZ * xRange + x) * 4;
        imageData.data[index + 2] = cellDAPISlice[x] !== 0 ? 255 : 0;
        imageData.data[index] = mucusWGASlice[x] !== 0 ? 255 : 0;
        imageData.data[index + 3] = 255;
      }
    }

    tempCtx.putImageData(imageData, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, canvas.width, canvas.height);

    canvas.style.position = 'absolute';
    canvas.style.left = `${margin.left}px`;
    canvas.style.top = `${margin.top}px`;

    // Draw the axis using D3
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const xPhysicalMax = xRange * xScaleState;
    const zPhysicalMax = zValue * newStep;

    const xScaleD3 = d3.scaleLinear().domain([0, xPhysicalMax]).range([0, innerWidth]);
    const yScaleD3 = d3.scaleLinear().domain([0, zPhysicalMax]).range([innerHeight, 0]);

    const xAxis = d3.axisBottom(xScaleD3).ticks(10).tickFormat(d => `${d.toFixed(1)}`);
    const yAxis = d3.axisLeft(yScaleD3).ticks(10).tickFormat(d => `${d.toFixed(1)}`);

    svg.append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top + innerHeight})`)
      .call(xAxis);

    svg.append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .call(yAxis);

    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("x", margin.left + innerWidth / 2)
      .attr("y", margin.top + innerHeight + 40)
      .text("X (μm)");

    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `rotate(-90)`)
      .attr("x", -(margin.top + innerHeight / 2))
      .attr("y", 11)
      .text("Z (μm)");

  }, [yValue, xRange, zValue, yRange, xScaleState, newStep, matrixCellDAPI, matrixMucusWGA, margin.top, margin.bottom, margin.left, margin.right]);

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const svg = svgRef.current;

    const totalWidth = canvas.width + margin.left + margin.right;
    const totalHeight = canvas.height + margin.top + margin.bottom;

    const svgData = new XMLSerializer().serializeToString(svg);
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);

    const canvasImg = new Image();
    const svgImg = new Image();

    canvasImg.onload = () => {
      svgImg.onload = () => {
        const combinedCanvas = document.createElement('canvas');
        combinedCanvas.width = totalWidth;
        combinedCanvas.height = totalHeight;
        const combinedCtx = combinedCanvas.getContext('2d');

        combinedCtx.drawImage(svgImg, 0, 0);
        combinedCtx.drawImage(canvasImg, margin.left, margin.top);

        combinedCanvas.toBlob((blob) => {
          const link = document.createElement('a');
          link.download = `${file_name}_img${img_num}_y=${yValue}_slice.png`;
          link.href = URL.createObjectURL(blob);
          link.click();

          URL.revokeObjectURL(svgUrl);
        });
      };
      svgImg.src = svgUrl;
    };
    canvasImg.src = canvas.toDataURL();
  };

  return (
    <div className="container">
      <h1>YSlice Image</h1>
  
      {cellDAPISize && mucusWGASize && (
        <div className="input-container" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
          <label style={{ fontSize: '18px', color: 'black', marginRight: '10px' }}>y (0 ~ {yRange - 1}) = </label>
          <input
            type="number"
            value={yValue}
            onChange={(e) => {
              const value = Math.max(0, Math.min(yRange - 1, Number(e.target.value)));
              setYValue(value);
            }}
            placeholder={`Enter y value (0 ~ ${yRange - 1})`}
            style={{ fontSize: '16px', padding: '10px', marginRight: '10px', width: '100px' }}
          />
          <button 
            onClick={downloadImage} 
            style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
          >
            Download (PNG)
          </button>
        </div>
      )}
  
      <div style={{ position: "relative", width: "800px", height: "400px", marginTop: '20px' }}>
        <canvas ref={canvasRef} 
                style={{ 
                  position: "absolute", 
                  top: `${margin.top}px`, 
                  left: `${margin.left}px`,
                  border: '1px solid white' 
                }}></canvas>
        <svg ref={svgRef} 
             style={{ position: "absolute", top: 0, left: 0, width: '800px', height: '400px' }}></svg>
      </div>
  
      <style>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;
          text-align: center;
        }
      `}</style>
    </div>
  );
}

export default NewYslice;
