// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebase'; // 确保导入了 Firebase auth

const UserContext = createContext();

// 自定义 Hook，方便在其他组件中使用
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  // 初始化时从 localStorage 中读取 user 信息
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  // 当 user 状态改变时，同步更新 localStorage
  const setUserAndStore = (newUser) => {
    setUser(newUser);
    if (newUser) {
      localStorage.setItem('user', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('user');
    }
  };

  // 添加 useEffect，监听 Firebase 认证状态的变化
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      console.log('Firebase auth state changed:', firebaseUser);
      if (firebaseUser) {
        const newUser = {
          uid: firebaseUser.uid,
          email: firebaseUser.email,
        };
        setUserAndStore(newUser);
      } else {
        setUserAndStore(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser: setUserAndStore }}>
      {children}
    </UserContext.Provider>
  );
};
