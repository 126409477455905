// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from './UserContext';
import LoginPage from './Step0_LoginPages';
import AnnouncementPage from './Step1_Announcement';
import DataUploaderPage from './Step2_DataUploader';
import DataManagementPage from './Step4_DataManagement';
import Sidebar from './Sidebar';
import ChooseAnalyzeConditionPage from './Step3_ChooseAnalyzeCondition';
import SeeAnalyzeResultPage from './Step3_SeeAnalyzeResult';

function App() {
  const { user } = useUser();

  return (
    <Router>
      <div className="app" style={{ display: 'flex', height: '100vh' }}>
        {/* 侧边栏：若用户已登录则显示 */}
        {user && <Sidebar />}
        <div className="content" style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <Routes>
            {/* 登录页面或重定向到公告页面 */}
            <Route path="/" element={user ? <Navigate to="/announcement" /> : <LoginPage />} />

            {/* 公告页面 */}
            <Route path="/announcement" element={user ? <AnnouncementPage /> : <Navigate to="/" />} />

            {/* 数据上传页面 */}
            <Route path="/uploader" element={user ? <DataUploaderPage /> : <Navigate to="/" />} />

            {/* 数据分析 - 重定向 /analyzer 到 /analyzer/choose */}
            <Route path="/analyzer" element={user ? <Navigate to="/analyzer/choose" /> : <Navigate to="/" />} />

            {/* 选择分析条件页面 */}
            <Route path="/analyzer/choose" element={user ? <ChooseAnalyzeConditionPage /> : <Navigate to="/" />} />

            {/* 查看分析结果页面 */}
            <Route path="/analyzer/result" element={user ? <SeeAnalyzeResultPage /> : <Navigate to="/" />} />

            {/* 数据管理页面 */}
            <Route path="/management" element={user ? <DataManagementPage /> : <Navigate to="/" />} />

            {/* 未匹配的路径重定向到主页 */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
