import React, { useState } from 'react';

function AnnouncementPage() {
  // State for toggling between English and Chinese
  const [isEnglish, setIsEnglish] = useState(true);

  // Toggle function for switching languages
  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  
  // Content in English
  const englishContent = (
    <div>
      <h2>Mucus Analyze Website Introduction and Guideline</h2>
      <p>This website integrates a mucus analysis module for biofluorescence imaging with a mucus analysis database, providing researchers with an efficient tool to analyze mucus distribution and generate meaningful, representative metrics. The database allows users to record both raw and analyzed data, enabling easy management of personal research. The website is divided into three main sections: Data Uploader, Data Analyzer, and Data Management.</p>

      <h3>Data Analysis Process</h3>
      <p><strong>Data Uploader</strong>: This page allows users to browse and select 3D mucus fluorescence samples stored on their local computer (only .lif files are accepted). By clicking the “Upload” button, users can upload their files to the server supported by the website. Please avoid closing this page during the upload process, as this will interrupt the upload. Once uploaded, a table will display the file name and the 3D image file numbers.</p>

      <p><strong>Data Analyzer</strong>:</p>
      <p>1st Choose Analyze Condition: In this section, users can select an uploaded file and image number, choose Bio-marker and Threshold settings, and initiate mucus analysis. The “Preview” button allows users to review preliminary results of the analysis parameters (slices at y=350 and y=700) and fine-tune the Threshold based on the preview. After confirming the settings, users can click “Submit and Analyze” to upload the analysis parameters to the server for processing. Once submitted, analysis will start on the server, and users can check the progress in the “Data Management” page. Users can continue with additional analyses without waiting for the current analysis to complete, optimizing their time.</p>

      <p>2nd See Analyze Result: In this section, users can select the name and image number of completed analyses to retrieve the saved results from the database, with four display modes available: “Heat Map,” “Y Slice,” “Statistic,” and “Distribution.”</p>

      <p><strong>Data Management</strong>: This page allows users to view the number of data files in their database, identify valid 3D image data, and check which 3D image data has been analyzed. It also provides a delete function to help users manage and remove unnecessary files.</p>
    </div>
  );

  // Content in Chinese
  const chineseContent = (
    <div>
      <h2>Mucus Analyze Website Introduction and Guideline</h2>
      <p>本網站結合了生物螢光影像的黏液分析模組與黏液分析資料庫，提供研究人員更高效的工具來分析生物螢光影像中的黏液分布以及多項有意義的指標。資料庫功能則幫助使用者記錄其原始數據及分析後的結果，使他們能透過網站便捷管理個人研究。網站主要分為三個部分：Data Uploader、Data Analyzer 和 Data Management。</p>

      <h3>數據分析流程</h3>
      <p><strong>Data Uploader</strong>: 此頁面讓使用者從本地端選取並上傳三維黏液螢光樣品（僅限 .lif 檔案）。使用者點擊 "Upload" 按鈕後即可將檔案上傳至網站支援的伺服器。上傳期間請勿關閉頁面，以避免中止上傳。完成後將顯示上傳檔案的名稱及對應的 3D 影像檔案編號。</p>

      <p><strong>Data Analyzer</strong>:</p>
      <p>1st Choose Analyze Condition：在此階段，使用者可選擇已上傳的檔案名稱及影像編號，並設定 Bio-marker 與 Threshold 進行黏液分析。點擊 "Preview" 按鈕可預覽部分分析結果（y=350 和 y=700 的切片圖），使用者可依據預覽切片影像微調 Threshold 值，確認無誤後點擊 "Submit and Analyze" 將參數上傳至伺服器開始正式分析。分析開始後，使用者可透過 Data Management 分頁確認分析狀態，並在等待分析完成的同時進行其他分析以節省時間。</p>

      <p>2nd See Analyze Result：此頁面讓使用者選取已完成分析的資料名稱及影像編號，從資料庫中調取分析結果，並以本網站支援的四種模式展示分析結果，包括："Heat Map"、"Y Slice"、"Statistic" 和 "Distribution"。</p>

      <p><strong>Data Management</strong>: 此頁面顯示使用者資料庫中的數據檔案數量，標記有效的三維影像數據及已分析數據，並提供刪除功能以便使用者管理及刪除不必要的檔案。</p>
    </div>
  );

  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '10%',
      transform: 'translateX(-50%)',
      textAlign: 'left',
      width: '100%',
      maxWidth: '800px'
    }}>
      <button 
        onClick={toggleLanguage} 
        style={{
          padding: '8px 16px',
          marginBottom: '20px',
          fontSize: '16px',
          cursor: 'pointer'
        }}>
        {isEnglish ? 'Switch to Chinese' : 'Switch to English'}
      </button>
      {isEnglish ? englishContent : chineseContent}
    </div>
  );
}

export default AnnouncementPage;
