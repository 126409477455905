import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';

function NewTopView({ zThickness, scaleData ,file_name, img_num }) {
  const [matrixData, setMatrixData] = useState([]);
  const [xRange, setXRange] = useState(1024);
  const [yRange, setYRange] = useState(1024);
  const [newZRange, setNewZRange] = useState(0);
  const [newStep, setNewStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef(null);
  const downloadRef = useRef(null);

  useEffect(() => {
    if (!zThickness || !scaleData) {
      alert("Global data is not available.");
      return;
    }
    
    setLoading(true);

    try {
      setXRange(scaleData.x_range);
      setYRange(scaleData.y_range);
      setNewZRange(scaleData.new_z_range);
      setNewStep(scaleData.new_step);

      setMatrixData(zThickness);
    } catch (error) {
      console.error("Error fetching data from globals: ", error);
    } finally {
      setLoading(false);
    }
  }, [zThickness, scaleData]);

  useEffect(() => {
    if (matrixData.length === 0 || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const scale = 0.5;
    const width = xRange * scale;
    const height = yRange * scale;
    //const colorBarWidth = 15;

    canvas.width = width + 85;
    canvas.height = height + 65;

    const imageData = context.createImageData(xRange, yRange);
    const colorScale = d3.scaleSequential(d3.interpolateTurbo).domain([0, newZRange]);

    for (let y = 0; y < yRange; y++) {
      const reversedY = yRange - 1 - y;
      for (let x = 0; x < xRange; x++) {
        const value = matrixData[reversedY][x];
        const color = d3.rgb(colorScale(value));

        const index = (y * xRange + x) * 4;
        imageData.data[index] = color.r;
        imageData.data[index + 1] = color.g;
        imageData.data[index + 2] = color.b;
        imageData.data[index + 3] = 255;
      }
    }

    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = xRange;
    tempCanvas.height = yRange;
    const tempCtx = tempCanvas.getContext('2d');
    tempCtx.putImageData(imageData, 0, 0);

    context.drawImage(tempCanvas, 0, 0, xRange, yRange, 25, 25, width, height);

    context.strokeStyle = "black";
    context.lineWidth = 1;

    context.beginPath();
    context.moveTo(25, 25);
    context.lineTo(25, height + 25);
    context.stroke();

    context.beginPath();
    context.moveTo(25, height + 25);
    context.lineTo(width + 25, height + 25);
    context.stroke();

    context.font = "8px Arial";
    context.fillStyle = "black";
    context.textAlign = "center";
    for (let i = 0; i <= xRange; i += xRange / 4) {
      context.fillText(i.toString(), 25 + (i * scale), height + 40);
    }

    context.textAlign = "right";
    context.textBaseline = "middle";
    for (let i = 0; i <= yRange; i += yRange / 4) {
      context.fillText(i.toString(), 20, height + 25 - (i * scale));
    }

    context.font = "10px Arial";
    context.fillText("(X-axis)", width + 55, height + 45);

    context.textAlign = "center";
    context.fillText("(Y-axis)", 20, 10);

    const colorBarHeight = height - 15;
    const barX = width + 40;
    const barY = 25;

    for (let i = 0; i <= newZRange; i++) {
      const color = colorScale(i);
      context.fillStyle = color;
      context.fillRect(barX, barY + ((newZRange - i) * (colorBarHeight / newZRange)), 15, colorBarHeight / newZRange);
    }

    context.font = "10px Arial";
    context.fillStyle = "black";
    context.textAlign = "left";
    context.textBaseline = "middle";
    for (let i = 0; i <= newZRange; i += newZRange / 4) {
      context.fillText(i.toString(), barX + 20, barY + ((newZRange - i) * (colorBarHeight / newZRange)) + (colorBarHeight / (newZRange * 2)));
    }
  }, [matrixData, xRange, yRange, newZRange]);

  const savePNG = () => {
    const canvas = canvasRef.current;
    const pngUrl = canvas.toDataURL("image/png");
    const downloadLink = downloadRef.current;
    downloadLink.href = pngUrl;
    downloadLink.download = `${file_name}_img${img_num}_mucus_heatmap.png`;
    downloadLink.click();
  };

  return (
    <div className="container">
      {/* 独立的标题容器 */}
      <div className="title-container">
        <h1 style={{ 
          marginTop: '0%', 
          marginBottom: '0%',
          marginLeft: '-20%',  

        }}>
          Mucus Thickness</h1>
      </div>
  
      <div className="content-container">
        {/* 左侧的图像显示部分 */}
        <div className="chart-container">
          <canvas ref={canvasRef}></canvas>
        </div>
  
        {/* 右侧的文字和按钮 */}
        <div className="info-container">
          <h3 
            style={{ 
              fontSize: '1em',           
              marginTop: '-110%',           
              marginLeft: '5%',         
              marginBottom: '5%',        
              marginRight: '5%',          
              whiteSpace: 'nowrap'       
            }}
          >
            Color Scale Step: {parseFloat(newStep).toFixed(3)} (μm)
          </h3>
          <button 
            onClick={savePNG} 
            style={{ 
              marginTop: '0px', 
              marginLeft: '10px',      
              padding: '10px 20px',    
              fontSize: '16px',        
              cursor: 'pointer',
              backgroundColor: '#f0f0f0',
              border: '1px solid #ccc',
              borderRadius: '3px'
            }}
          >
            Download (PNG)
          </button>
        </div>
      </div>
  
      {loading && (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      )}
  
      <a ref={downloadRef} href="/" style={{ display: "none" }}>Download</a>
  
      <style>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;
          text-align: center;
        }
  
        /* 独立标题容器的样式 */
        .title-container {
          width: 100%; /* 让标题独占一行 */
          text-align: center;
        }
  
        .content-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          margin-left: 10%; /* 使用百分比向右平移整个内容区域 */
        }
  
        .chart-container {
          position: relative;
          width: 512px;
          height: 512px;
        }
  
        canvas {
          width: 100%;
          height: 100%;
          border: 2px solid #FFFFFF;
        }
  
        .info-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 20px; /* 设置图像与信息区的水平间距 */
        }
  
        .loading-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
  
        .spinner {
          margin: 20px 0;
          border: 8px solid #f3f3f3;
          border-top: 8px solid #007bff;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          animation: spin 1s linear infinite;
        }
  
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
  
}

export default NewTopView;