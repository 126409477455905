// LoginPage.js
import React from 'react';
import { auth, provider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import { useUser } from './UserContext';

function LoginPage() {
  const { setUser } = useUser();

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = {
        email: result.user.email,
        uid: result.user.uid,
      };
      setUser(user); // 設置 user 全局狀態，並存儲在 localStorage 中
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      marginTop: '15%',
      textAlign: 'center'
    }}>
      <h1 style={{ marginBottom: '0%' }}>Welcome to Mucus Website!</h1>
      <p style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '1%' }}>Login to Continue</p>
      <button onClick={handleLogin} style={{ padding: '10px 20px', fontSize: '16px' }}>
        Login with Google Account
      </button>
    </div>
  );
}

export default LoginPage;
