// NewStatisticValuePrint.js
import React, { useEffect } from 'react';

function NewStatisticValuePrint({ stasticData }) {
  // 默认加载时显示统计数据
  useEffect(() => {
    if (!stasticData) {
      console.error("Statistics data is not available.");
    }
  }, [stasticData]);

  return (
    <div className="container">
      <h1>Mucus Statistic Data</h1>

      {stasticData ? (
        <div className="statistics">
          <h2>Statistics</h2>
          <div className="stat-item">
            <span className="label"><strong>Mucus Coverage:</strong></span>
            <span className="value"><strong>{stasticData.coverage.toFixed(2)} ( % )</strong></span>
          </div>
          <div className="stat-item">
            <span className="label"><strong>Mucus Mean:</strong></span>
            <span className="value"><strong>{stasticData.overall_mean.toFixed(2)} ( µm )</strong></span>
          </div>
          <div className="stat-item">
            <span className="label"><strong>Mucus Variance:</strong></span>
            <span className="value"><strong>{stasticData.overall_variance.toFixed(2)} ( µm )</strong></span>
          </div>
          <div className="stat-item">
            <span className="label"><strong>Mucus Volume:</strong></span>
            <span className="value"><strong>{stasticData.z_volume_count.toFixed(2)} ( µm^3 )</strong></span>
          </div>
        </div>
      ) : (
        <p>Loading statistics data...</p>
      )}

      <style>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;
          text-align: center;
        }

        .statistics {
          margin-top: 20px;
          text-align: left; 
          width: 420px; 
        }

        .statistics h2 {
          margin-bottom: 10px;
        }

        .stat-item {
          display: flex;
          justify-content: space-between;
          width: 100%; 
          margin-bottom: 8px;
          margin: 10px 0;
        }

        .label {
          text-align: left;
        }

        .value {
          text-align: right;
        }

        .statistics p {
          margin: 5px 0;
          font-size: 16px;
        }
      `}</style>
    </div>
  );
}

export default NewStatisticValuePrint;
